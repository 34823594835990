<template>
    <div class="pay_buy_more" id="agentTitle">
        <header>
            <div class="img_box_"><img src="https://img.nnabc.cn/WechatIMG2554.jpeg" alt=""></div>
        </header>
        <section>
            <div class="box_">
                <p class="p_title__">支付获取您的：</p>
                <h1 class="h1_title__">1000字姓名全面详解</h1>
                <p class="p1_text__">伴你一生的名字，它对你好吗</p>
                <p class="p1_text__">专家专业解答</p>
                <div class="number_box__">
                    <span class="span_left___">
                        <span class="top____">原价: ￥{{ price.fake_amount }}</span>
                        <span class="bottom____">￥{{ price.measure_amount }}</span>
                    </span>
                    <span class="span_right___">
                        <span class="top____">限时优惠结束</span>
                        <span class="bottom____">
                            <van-count-down  @change="timeTrigger" millisecond :time="time">
                                <template #default="timeData">
                                    <span class="block_">0{{ timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">0{{ timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ timeData.seconds &lt; 10 ? '0'+timeData.seconds : timeData.seconds }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                </template>	
                            </van-count-down>
                        </span>
                    </span>
                </div>
                <button class="button_we_chat___" @click="getpayurl('wechat','v1_pay_wechat')"><img src="https://img.nnabc.cn/25161650364496_.pic_hd.jpg" alt=""></button>
                <p class="p2_text___">已有<span class="color____">9, 316, 618</span>人查看，<span class="color____">98.7%</span>认为对人生发展有帮助</p>
                <div class="img_box__"><img src="https://img.nnabc.cn/WechatIMG2543.png" alt=""></div>
            </div>
            <p class="p_text">- 姓名测试后你将了解如下信息 -</p>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2548.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2552.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2553.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2551.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2549.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2550.jpeg" alt="">
            </div>
        </section>
        <footer>
            <button class="button_box_ animation_scaling" v-if="fixedbtn" @click="pay('v1_testnamepay_bottom_btn')">支付后查看测名结果</button>
        </footer>
    </div>
</template>

<script>
import Comments from "../components/Comment.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import orderscroll from "../components/OrderScroll.vue";
import axios from "../utils/request";
import solarLunar from "../components/datepick/solarlunar";
import { CountDown } from "vant";

import { Overlay } from "vant";
import { Toast } from "vant";

import { Dialog } from "vant";

import router from "../router";

import report from "../utils/stat";
export default {
   name: "Pay",
    components: {
        Comments,
        vueSeamlessScroll,
        vanCountDown: CountDown,
        vanOverlay: Overlay,
        vanToast: Toast,
        orderscroll
    },
    data( ) {
        return {
            time:localStorage.getItem("time"),//计时器
            fixedbtn:false, //是否隐藏底部付费解锁全部内容按钮
            buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG610.jpeg',
            buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG606.png',
            buttom_src_img_fal:true,
            counttime:10*60*1000,
            listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orderlist: [],
            activeNames: ["1"],
            showPayDialog: false,
            showfixPayBtn: true,
            shopPayConfirm: false,
             // shopPayConfirm: true,//支付后弹窗
            fixedbtn: false,
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                    bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
                    nayin: [
                        { y: "壁上土" },
                        { m: "沙中金" },
                        { d: "海中金" },
                        { h: "海中金" },
                    ],
                },
                askTao: {
                    qk_score_ratio: {
                        金: "6%",
                        木: "11%",
                        水: "25%",
                        火: "12%",
                        土: "46%",
                    },
                },
				scwg:{
					"fiveGirl":
					{"天":16,"地":10,"人":24,"总":24,"外":2},
				},
				hanzimean:{xm: "李晓丽", ft: "李曉丽", pinyin: "lǐ#xiǎo#lì#", kxbihua: "7#16#19#", wx: "火火火"}
            },
            price: {
                base_level: null,
                created_at: null,
                fake_amount: "198.00",
                fast_amount: null,
                id: 1,
                true_amount: "",
                type: 1,
                updated_at: null,
            },
            orderfakedata: [{
                    name: "王*慧",
                    time: "12分钟前",
                },
                {
                    name: "李*",
                    time: "7分钟前",
                },
                {
                    name: "孙*飞",
                    time: "3分钟前",
                },
                {
                    name: "刘*云",
                    time: "5分钟前",
                },
                {
                    name: "赵*",
                    time: "1分钟前",
                },
                {
                    name: "周**",
                    time: "3分钟前",
                },
                {
                    name: "李*惠",
                    time: "3分钟前",
                },
                {
                    name: "古*歌",
					time: "5分钟前",
                },
            ],
        }
    },
    
    async created(){
        this.initData();
        if ( window.localStorage.getItem('cm_red_packet_type') === null) {
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG606.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG610.jpeg'
        }else{
            this.buttom_src_img_fal = false
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG612.jpeg'
        }
		report("v1_testnamepay");
        console.log(this.$route)
        let paytype = this.$route.params.type;
        this.orderid = this.$route.params.id;
        this.type = this.$route.params.type;
        if (paytype != 10) {
            try {
                await this.mcheckorder();
            } catch (err) {}
            this.shopPayConfirm = true;
        } else {}
        this.getorderinfo(this.$route.params.id);
        this.getFakeOrder();
        await this.getprice();
        this.showToast();
		// window.addEventListener("scroll", this.handleScroll);
        
    },
    computed:{
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.listData.length,
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                openTouch: false,
            };
        },
		xm(){
			return this.orderinfo.xname+this.orderinfo.name
		},
        dialogwidth() {
            return window.innerWidth * 0.9;
        },
    },
    async mounted() {
        sessionStorage.setItem('orderid',this.$route.params.id);

	    if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}
	
	    document
	        .getElementById("agentTitle")
	        .scrollIntoView({ block: "start", behavior: "smooth" });
			document.title = "诸葛国学测名";
    },
    methods:{
        initData( ) {
            window.addEventListener("scroll", this.handleScroll);
            if( localStorage.getItem("time") === null ) {
                localStorage.setItem("time","600000");
                this.time = 600000;
            }else if ( localStorage.getItem("time") == 0 ){
                localStorage.setItem("time","600000");
                this.time = 600000;
            }
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        timeTrigger (TimeData) {//计时器变化触发(支付倒计时)
            //每次触发计时器后存到本地储存里，保证页面刷新计时器不重新开始
            if( localStorage.getItem("time") === null ) {
                this.time = 600000;
            }else if ( localStorage.getItem("time") == 0 ){
                this.time = 600000;
            }else{
                let MM = TimeData.minutes * 60
                let ss = TimeData.seconds + MM
                let mss_ = TimeData.milliseconds + (ss*1000)
                localStorage.setItem("time",mss_);
                
            }
        },
        pay(action) {
			this.hasclick = true;
			document
			    .getElementById("agentTitle")
			    .scrollIntoView({ block: "start", behavior: "smooth" });
            report(action);
        },
        unpay(){
            // router.back();
            if ( window.localStorage.getItem('cm_red_packet_type') === null) {
                this.$router.replace('/aitestname')
            }else{
                // location.href = "weixin://dl/business/?t=dqnSN5t1Ehe";
            }
        },
		handleScroll() {
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        async getFakeOrder() {
            let info = await axios.post("/ekaf", {
                type: 2,
                perPage: 15,
                page: 1,
                fromk: this.$route.query.from
            });
            this.orderlist = info.data.data;
        },

        async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},

        showToast() {
            let t = this;
            let arr = this.orderfakedata;
            let data = arr[Math.floor(Math.random() * arr.length)];
            Toast({
                message: `${data.time} ${data.name}支付了${this.price.measure_amount}元测试姓名`,
                position: "bottom",
                duration: 2000,
            });
            this.timers = setTimeout(function() {
                t.showToast();
            }, 5000);
        },

        async timeout(time) {
            return new Promise((sol, ret) => {
                setTimeout(() => {
                    sol()
                }, time);
            })
        },

        async getpayurl(payway, aciton) {
            if ( payway === '') {
                payway = this.$route.params.type === '1' ? 'alipay' : 'wechat'
             }
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
			if(this.hasclick){
				report(aciton+"_content");
			}else{
				report(aciton);
			}

            if ( this.is_weixn ) {
                setInterval( ()=> {this.checkorder(orderid, 2);}, 2000)
                
            }
    
            let orderid = this.orderid;
            let type = payway == "alipay" ? 1 : 2;
            this.type = type;
            let orderinfo = await axios.get("/order/" + orderid);
            if (
                orderinfo.data.data.order_status == "已完成" ||
                orderinfo.data.data.order_status == "已支付"
            ) {
                
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    sessionStorage.setItem('aitestsuccess','1');
                    let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        router.replace({ name: "aitestsuccess", params: { id: orderid, type } }).catch(() => {});
                    }else{
                        router.replace({ name: "testresult", params: { id: orderid, type } }).catch(() => {});
                    }
                });
                return;
            }
            let t;
            
            setTimeout(async function() {
                await t.checkorder(orderid, type);
            }, 2000);
            try {
                let confirm_order_data_ = {
                    order_id: orderid,
                    paytype: type,
                }
                await this.confirmOrder(confirm_order_data_);
                Toast.clear()
                // location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                this.shopPayConfirm = true;

                if (this.is_weixn) {
						let payinfo = await axios.get(
							`https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}/wechat`)
						let pay = payinfo.data.data;

						wx.ready(function() {
							wx.chooseWXPay({
								timestamp: pay.timeStamp,
								nonceStr: pay.nonceStr,
								package: pay.package,
								signType: pay.signType,
								paySign: pay.paySign, // 支付签名
								success: function(res) {
                                    let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                        router.replace({
                                            name: "aitestsuccess",
                                            params: { id:orderid, type:2 }
                                        }).catch(() => {});
                                    }else{
                                        router.replace({
                                            name: "testresult",
                                            params: { id:orderid, type:2 }
                                        }).catch(() => {});
                                    }
								}
							});
						});


					} else {
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
					}



            } catch (err) {}
        },
        async getprice() {
			 let from = this.$route.query.from || 10;
            let price = await axios.get(`/getSalesPrice?fromk=${from}`);
          
            this.price = price.data.data[0];
        },
        async getorderinfo(id) {
            let data = await axios.get("/order/" + id);
            this.orderinfo = data.data.data;
            // console.log('fromk',this.orderinfo.fromk === 'tk2')
            // if (
            //     this.orderinfo.order_status == "已支付" ||
            //     this.orderinfo.order_status == "已完成"
            // ) {
            //     Dialog.confirm({
            //         message: "当前订单已支付",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "paysuccess", params: { id, type: 1 } });
            //     });
            // } else if (this.orderinfo.order_status == "已关闭") {
            //     Dialog.confirm({
            //         message: "当前订单已关闭",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "Index" }).catch(() => {}); // on confirm
            //     });
            // }
            let birth = data.data.data.pub_birth_date ?
                data.data.data.pub_birth_date :
                data.data.data.pub_prebirth_date;
            let fdate = birth.split(" ");
            this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
            this.orderinfo.nongli = solarLunar.solar2lunar(
                this.orderinfo.bDate[0],
                this.orderinfo.bDate[1],
                this.orderinfo.bDate[2]
            );
            let nowtime = Date.now();
            let cDate = data.data.data.created_at.replace(" ", "T");
            let createtime = new Date(cDate).getTime();
            // this.time = 10 * 60 * 1000 - nowtime + createtime;
        },
        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        sessionStorage.setItem('aitestsuccess','1');
                        let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            router.replace({
                                 name: "aitestsuccess",
                                 params: { id, type: data.data.data.type },
                             }).catch(() => {});
                        }else{
                            router.replace({
                                name: "testresult",
                                params: { id, type: data.data.data.type },
                            }).catch(() => {});
                        }
                    }
                } catch (err) {}
            }, 5000);
        },
        async mcheckorder() {
            let data = await axios.post("/payment/query", {
                paytype: this.type,
                order_id: this.orderid,
            });
            if (data.data.data.is_pay) {
                sessionStorage.setItem('aitestsuccess','1');
                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                    router.replace({
                        name: "aitestsuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                }else{
                    router.replace({
                        name: "testresult",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                }
            } else {
                Toast.fail("当前订单未支付");
            }
        },
        async Dcheckorder() {
            try {
                this.mcheckorder();
                let data = await axios.post("/payment/query", {
                paytype: this.type,
                order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('aitestsuccess','1');
                    let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        router.replace({
                            name: "aitestsuccess",
                            params: { id: this.orderid, type: data.data.data.paytype },
                        }).catch(() => {});
                    }else{
                        router.replace({
                            name: "testresult",
                            params: { id: this.orderid, type: data.data.data.paytype },
                        }).catch(() => {});
                    }
                } else {
                    this.getpayurl('','')
                    // Toast.fail("当前订单未支付");
                }

            } catch (err) {
                this.getpayurl('','')
                // Toast.fail("当前订单未支付");
            }
        },
        async confirmOrder(confirm_order_data_) {
            try {
                if ( window.localStorage.getItem('cm_red_packet_type') === null) {
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: confirm_order_data_.paytype,
                    });
                }else{
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: 2,
                        discount_id: 1
                    });
                }
            } catch (err) {}
        },
        bottombtnclick() {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 300) {
                this.fixedbtn = true;
            } else {
                this.fixedbtn = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .pay_buy_more{
        h1,h2,h3,h4,h5,h6,p{
            margin: 0;
            padding: 0;
        }
        img{
            width: 100%;
            height: 100%;
            display: block;
        }
        button{
            background: none;
            border: none;
        }
        header{
            .img_box_{
                width: 7.5rem;
                height: 3.32rem;
            }
        }
        section{
            margin-bottom: 1.15rem;
            .box_{
                width: 7.18rem;
                background: #FFFFFF;
                border-radius: .10rem;
                margin: auto;
                overflow: hidden;
                .p_title__{
                    width: 7.18rem;
                    height: .62rem;
                    line-height: .62rem;
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    text-indent: .24rem;
                    color: #62615D;
                    background: rgba(242, 238, 223, .3);
                    margin-top: .33rem;
                    margin-bottom: .3rem;
                }
                .h1_title__{
                    font-size: .48rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #323232;
                    text-align: center;
                    margin-bottom: .24rem;
                }
                .p1_text__{
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #323232;
                    text-align: center;
                }
                .number_box__{
                    display: flex;
                    justify-content: space-between;
                    margin-top: .47rem;
                    .span_left___{
                        display: flex;
                        flex-direction: column;
                        margin-left: .58rem;
                        .top____{
                            text-decoration: line-through;
                            font-size: .24rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #979797;
                        }
                        .bottom____{
                            font-size: .66rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #FD0101;
                            text-indent: -0.12rem;
                        }
                    }
                    .span_right___{
                        display: flex;
                        flex-direction: column;
                        width: 2.48rem;
                        height: 1.2rem;
                        margin-right: .35rem;
                        border-left: .01rem solid rgba(151, 151, 151, 1);
                        .top____{
                            font-size: .24rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #323232;
                            text-align: right;
                            margin-bottom: .15rem;
                        }
                        .bottom____{
                            text-align: right;
                            .colon {
                                display: inline-block;
                                margin: 0 .04rem;
                                font-size: .2rem;
                                color: #E7010E;
                            }
                            .block_ {
                                display: inline-block;
                                width: .43rem;
                                color: #fff;
                                font-size: .24rem;
                                text-align: center;
                                background-color: #E7010E;
                                box-sizing:border-box;
                                border: 1px solid #E7010E;
                            }
                        }
                    }
                }   
                .button_we_chat___{
                    display: block;
                    width:5.60rem;
                    height: .95rem;
                    margin: auto;
                    margin-top: .6rem;
                    margin-bottom: .22rem;
                    img{
                        display: block;
                    }
                }
                .p2_text___{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .22rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #525252;
                    margin-bottom: .31rem;
                    .icon____{
                        display: inline-block;
                        width: 1.06rem;
                        height: .35rem;
                    }
                    .color____{
                        color: #E7010EFF;
                        margin-top: .04rem;
                    }
                }
                .img_box__{
                    width: 6.41rem;
                    height: .77rem;
                    margin: auto;
                    margin-bottom: .43rem;
                }
                .p3_text__{
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #C94B4B;
                    margin-bottom: .32rem;
                    margin-left: .41rem;
                }
                .p4_text__{
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgba(50, 50, 50, 1);
                    margin-bottom: .28rem;
                    margin-left: .41rem;
                }
                .p4_text__:nth-last-child(1){
                    margin-bottom: .6rem;
                }

            }
            .p_text{
                font-size: .32rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                text-align: center;
                margin: .28rem 0;
            }
            .img_box_{
                width: 7.18rem;
                margin: auto;
                margin-top: .13rem;
            }
        }
        footer{
            height: 1.11rem;
           .button_box_{
               position: fixed;
               bottom: 0;
               width: 7.5rem;
               height: 1.11rem;
               background: #B95353;
               font-size: .36rem;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #FEFBFB;
           } 
        }
    }
</style>